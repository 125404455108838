import * as AsyncStorage from 'rev.sdk.js/Utils/AsyncStorage';
import {getNewOutlet} from 'reconnect.js';

const ProductHistory = getNewOutlet('local-product-history', [], {
  autoDelete: false,
});

export async function loadLocalProductHistory() {
  try {
    let result = await AsyncStorage.getItem('local-product-history');
    // store product ids
    let histories = JSON.parse(result);
    if (Array.isArray(histories)) {
      ProductHistory.update(histories);
    }
  } catch (err) {}
}

export function addToLocalHistory(p) {
  if (!p.id) {
    p.id = p._id.$oid;
  }

  let prev = ProductHistory.getValue();

  let nextData = [p.id, ...prev].slice(0, 5);
  ProductHistory.update(nextData);
  AsyncStorage.setItem('local-product-history', JSON.stringify(nextData));
}

export function deleteLocalHistory(id) {
  let prev = ProductHistory.getValue();
  let index = prev.indexOf(id);

  if (index > -1) {
    let nextData = [...prev];
    nextData.splice(index, 1);
    ProductHistory.update(nextData);
    AsyncStorage.setItem('local-product-history', JSON.stringify(nextData));
  }
}

export function clearAllHistory() {
  ProductHistory.update([]);
  AsyncStorage.setItem('local-product-history', JSON.stringify([]));
}
