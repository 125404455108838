const defaultLocalProfile = {
  rememberMe: false,
};

export function updateLocalProfile(values) {
  let localProfile = null;

  if (typeof window !== 'undefined') {
    try {
      localProfile = JSON.parse(window.localStorage.getItem('local-user'));
    } catch (ex) {
      console.warn(ex);
    }

    if (!localProfile) {
      localProfile = {...defaultLocalProfile};
    }

    window.localStorage.setItem(
      'local-user',
      JSON.stringify({
        ...localProfile,
        ...values,
      }),
    );

    return true;
  }

  return false;
}

export function getLocalProfile() {
  let localProfile = {};

  if (typeof window !== 'undefined') {
    try {
      localProfile = JSON.parse(window.localStorage.getItem('local-user'));
    } catch (ex) {
      console.warn(ex);
    }
  }

  return localProfile ? localProfile : {...defaultLocalProfile};
}

export function validateInvoice(invoiceType, carrier) {
  if (invoiceType === 'mobile_carrier') {
    return /^\/[0-9A-Z\+\-\.]{7}$/.test(carrier);
  } else if (invoiceType === 'uni_no') {
    return /^[0-9]{8}$/.test(carrier);
  } else if (invoiceType === 'donation_code') {
    return !!carrier;
  } else {
    return true;
  }
}
